<script>
import dayjs from 'dayjs'
import calendarDateVue from './component/calendarDate.vue'
import CellPicker from '@/components/cell-picker/index.vue'
import { getAppointmentDay } from '@/services/appointment'
import { dateFormat,arrayFormat,padTime,getTimeNowOrOld } from '@/utils'
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'
import AddressSystem from '@/components/addressSystem.vue'
import { getNearHour } from '@/utils/index.js'
import selectCar from '@/components/select-car'
export default {
  components:{ calendarDateVue,CellPicker,AddressSystem },
  filters: {
    formatCarInfo(info) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
  },
  props:{
    appointmentType:{
      type: String,
      default:'2001001'
    },
    data:{
      type: Object,
      default:()=>({})
    },
    showArrow:{
      type: Boolean,
      default:false
    }
  },  
  data() {
    const date = new Date()
    const that = this
    return {
      formData:{
        typeCode:'',
        start: new Date(padTime(dayjs().add(10, 'minute'))),
        end: new Date(padTime(dayjs().add(40, 'minute'))),
        vinCode:'',
        seriesCode: '',
        seriesName: '',
        carNumber: '',
        vehicleCode:'',
        vehicleName: '',
        driveProvinceCode:'', // 上门试驾省code
        driveProvinceName:'', // 上门试驾省名称
        driveCityCode:'', // 上门试驾市code
        driveCityName:'', // 上门试驾市名称
        driveDistrictCode:'', // 上门试驾区code
        driveDistrictName:'', // 上门试驾区名称
        driveAddress:'', // 上门试驾-详细地址
      },
      carInfo: {},
      minDate: date,
      modelList:[],
      testDriveList:[],
      list:[],
      id:'',
      clueId:'',
      addressKeys:{
        cityCode:'driveCityCode',
        city:'driveCityName',
        provinceCode:'driveProvinceCode',
        province:'driveProvinceName',
        countyCode:'driveDistrictCode',
        county:'driveDistrictName',
      },
      addressObj:{}
    }
  },  
  computed: {
    getTimeDate() {
      const { start,end } = this.formData
      return [start,end]
    },
    getAppointmentType() {
      return this.$store.getters.getDictHash(2002).filter(({ value }) => { return ['2002001','2002002'].includes(value) }) || []
    },
    dictMap(){
      const dictList = this.$store.getters.getDictHash('2007')
      const result = {}
      dictList.forEach((item)=>{
        result[item.code] = item.name
      })
      return result
    }
  },
  watch:{
    'formData.vehicleCode':{
      handler(val){
        if (val){
          this.getTestDriveList()    
          this.getAppointmentDay()      
        } else {
          this.testDriveList = []
          this.formData.vinCode = ''
        }
      },
      immediate: true
    },
    data:{
      handler(val){
        if (val && Object.keys(val).length){
          const { appointmentEndTime, appointmentStartTime, ...query } = val
          this.id = val.id
          const timeObj=getTimeNowOrOld(appointmentStartTime,appointmentEndTime)
          Object.assign(this.formData, val, timeObj)
          const { vehicleCode, vehicleName, seriesName, seriesCode } = val
          this.carInfo = {
            seriesName,
            seriesCode,
            modelName: vehicleName,
            modelCode: vehicleCode
          }
          if (val.typeCode==='2002002'){
            this.addressObj = {
              driveProvinceCode: val.driveProvinceCode,
              driveProvinceName: val.driveProvinceName,
              driveCityCode: val.driveCityCode,
              driveCityName: val.driveCityName,
              driveDistrictCode:val.driveDistrictCode,
              driveDistrictName:val.driveDistrictName,
            }
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    const { id,clueId } = this.$route.query
    if (id && clueId ){
      this.id = id
      this.clueId = clueId
      this.getClueDetail()
    }
    this.getModelList()
    // 设置默认时间
    // 获取当前时间最近的整点/半点时间
    let start = getNearHour(new Date())
    let end
    // 试驾时间只能是 9:00-24:00
    if (dayjs(start).isAfter(dayjs().hour(23).minute(30))){
      // 当前时间超过 23:30 则开始&结束时间都设置为 23:00
      start = dayjs().hour(23).minute(30).second(0).toDate()
      end = dayjs().hour(23).minute(30).second(0).toDate()
    } else if (dayjs(start).isBefore(dayjs().hour(9).minute(0))){
      // 当前时间早于 09:00 则开始设置为09:00，结束时间设置为 09:30
      start = dayjs().hour(9).minute(0).second(0).toDate()
      end = dayjs().hour(9).minute(30).second(0).toDate()
    } else {
      end = dayjs(start).add(30,'minute').toDate()
    }
    this.$set(this.formData,'start',start)
    this.$set(this.formData,'end',end)
  },
  mounted(){        
    // const date = dayjs()
    // const start = date.add(10, 'minute')
    // // const end = date.add(20, 'minute')
    // this.formData.start = new Date(this.padTime(start))
    // this.formData.typeCode = this.data.typeCode
    // this.formData.end = new Date(end)
    // this.getAppointmentDay()
  },
  methods:{
    changeDate(val,type){
      const [start='',end=''] = val
      this.formData.start = start
      this.formData.end = end
      if (start && type === 'date'){
        this.getAppointmentDay()
      } 
      if (!start) {
        this.list = []
      }      
    },
    changeCell(value, data) {
      this.formData.carNumber = data.carNumber
      this.formData.seriesCode = data.seriesCode
      this.formData.seriesName = data.seriesName
      this.getAppointmentDay()
    },
    changeVehicleCode(value, data) {
      this.formData.vehicleName = data.name
      this.formData.vehicleCode = data.code
      this.formData.seriesCode = ''
      this.formData.seriesName = ''
      this.formData.vinCode = ''
      this.list = []
    },
    // 选择意向车型 type:carInfo为意向信息  driveCarInfo为上门试驾车型
    selectCarModel() {
      let initCarInfo = this.carInfo
      selectCar({ initCarInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast('请选择正确的车系车型')
            return
          }
          const { modelCode, modelName, modelNameEn, seriesName, seriesCode } = carInfo
          if (modelCode !== this.formData.vehicleCode) {
            this.carList = []
            this.formData.carNumber = ''
            this.formData.vinCode = ''
            this.carInfo = carInfo
            this.formData.seriesName = seriesName
            this.formData.seriesCode = seriesCode
            this.formData.vehicleCode = modelCode
            this.formData.vehicleName = modelName
          }
          this.list = []
          this.$refs.carInfo.validate()
        })
        .catch(() => { })
    },
    // 提交
    async confirm() {
      await this.$refs.carInfo.validate()
      if (!this.formData.carNumber){
        this.$toast(this.$t('请选择试驾车'))
        return
      }
      if (this.formData.typeCode==='2002002'){
        if (!(this.formData.driveAddress.trim()&&(this.formData.driveProvinceCode||this.formData.driveCityCode||this.formData.driveDistrictCode))){
          this.$toast(this.$t('请选择上门试驾地址并填写详细地址'))
          return
        }
      }
      // let appointmentStartTime = '',appointmentEndTime = ''
      const { start, end, vehicleCode, ...query } = this.formData
      if (!start && !end){
        this.$toast(this.$t('请选择时间'))
        return
      }
      // appointmentStartTime = new Date(start).getTime()
      // appointmentEndTime = new Date(end).getTime()
      if (dayjs(end).isBefore(dayjs(start),'minute') || dayjs(end).isSame(dayjs(start),'minute')){
        this.$toast(this.$t('请确认，结束时间要大于开始时间'))
        return
      }
      const [ones1,ones2] = dateFormat(start,'HH:mm').split(':')
      const [twos1,twos2] = dateFormat(end,'HH:mm').split(':')
      let type = true
      const arr = this.list.filter((item)=>{
        const { id,appointmentTime } = item
        if (this.id !== id && appointmentTime){
          const [one,two] = appointmentTime.split('-')
          const [one1,one2] = one.split(':')
          const [two1,two2] = two.split(':')
          // 选择的开始时间是否在区间里        
          if ((ones1>one1 || (ones1===one1 && ones2>=one2)) && (ones1<two1 || (ones1===two1 && ones2 < two2))){      
            console.log(1)   
            type = false
            return item
          }
          // 选择的结束时间是否在区间里
          if ( (twos1>one1 || (twos1===one1 && twos2>one2)) && (twos1<two1 || (twos1===two1 && twos2 <= two2))){
            console.log(2)   
            type = false
            return item
          }
          // 选择的开始时间小于预约开始，选择的结束时间大于预约的结束
          if ( ((ones1<one1 || (ones1 ===one1 && ones2 < one2))) && (twos1>two1 || (twos1 === two1 && twos2 > two2))){
            console.log(3)
            type = false
            return item
          }
        }
      })
      console.log(type,arr,3333)
      if (!type){
        const name = arr.map(({ appointmentTime,followUserName,customerName },index)=>{
          return `${index+1}. ${followUserName || customerName} ${ this.$t('已预约') } ${appointmentTime}`
        })
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: this.$t('时间段已有以下排程，') + `\n${name.join('\n')}`,
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('确认'),
        }).then(() => {
          next(this)
        })
        return
      }
      next(this)
      function next(that) {
        if (vehicleCode && start && end && query.carNumber && query.typeCode) {
          const params = { ...query ,appointmentStartTime:start,appointmentEndTime:end,appointmentDate: dateFormat(start,'YYYY-MM-DD'), vehicleCode }
          that.$emit('confirm',params )
          
        } else {
          that.$toast(this.$t('请选择'))
        } 
      }
    },
    close() {
      this.$emit('close')
    },
    getAppointmentDay() {
      const { appointmentType,vinCode,formData } = this
      if (!formData.vinCode || !formData.start) return
      getAppointmentDay({
        appointmentType,
        vinCode:formData.vinCode,
        date: dateFormat(formData.start,'YYYY-MM-DD'),
        isSearchDealer: true
      }).then(res=>{
        this.list = res
      })
    },
    // // 向上取整
    // padTime (timeStr) {
    //   // timeStr = timeStr.replace(/-/g, '/')
    //   var oDate = new Date(timeStr)
    //   var stamp = oDate.getTime()
    //   var minute = oDate.getMinutes()
    //   var last = minute%10
    //   if (last) {
    //     stamp += (10-last) * 60 * 1000
    //   }
    //   oDate = new Date(stamp)

    //   var t = {
    //     year: pad_2_0(oDate.getFullYear()),
    //     month: pad_2_0(oDate.getMonth() + 1),
    //     day: pad_2_0(oDate.getDate()),
    //     hour: pad_2_0(oDate.getHours()),
    //     minute: pad_2_0(oDate.getMinutes()),
    //     second: pad_2_0(oDate.getSeconds())
    //   }

    //   var res = t.year + '/' + t.month + '/' + t.day + ' ' + t.hour + ':' + t.minute

    //   console.log(timeStr, '=>', res)
    //   return res
    //   function pad_2_0 (num) {
    //     return num >= 10 ? num : '0' + num
    //   }
    // },
    getClueDetail() {
      clueServices.getClueDetail({ id: this.clueId,appointmentId: this.id }).then(res=>{
        if (res.appointments && res.appointments.length){
          const { appointmentEndTime, appointmentStartTime, ...query } = res.appointments[0]
          // const start = appointmentStartTime
          this.formData = {
            ...query,
            start: appointmentStartTime,
            end: appointmentEndTime
          }
          this.minDate = appointmentStartTime
          this.getAppointmentDay()
        }
      })
    },
    // 车型
    getModelList() {
      baseDataServices.getModelList({}).then(res=>{
        this.modelList = res
      })
    },
    getTestDriveList() {
      baseDataServices.getTestDriveList({ modelCode:this.formData.vehicleCode,driveFlag: false }).then(res=>{
        this.testDriveList = res.filter(({ processFlag }) => !processFlag)
        const vinSelect = this.testDriveList.find(({ vinCode }) => (this.formData.vinCode == vinCode)) || {}
        this.formData.seriesCode = vinSelect.seriesCode
        this.formData.seriesName = vinSelect.seriesName
      })
    },
    /**
     * 
     * @param {*} val
     * userCity
     * userCityCode
     * userCounty
     * userCountyCode
     * userProvince
     * userProvinceCode 
     */
    onChangeAddress(val){
      console.log('onChangeAddress:',val)
      this.$set(this,'addressObj',val)
      Object.assign(this.formData,val)
    }
  }
}
</script>
<template>
  <div class="page">
    <div v-if="!$route.query.clueId || showArrow" class="nav">
      <div class="opt" @click="close">
        <van-icon name="arrow-left" size="18"></van-icon>
      </div>
      <!-- <div class="title">title</div> -->
    </div>
    <div class="info box">
      <!-- <CellPicker v-model="formData.vehicleCode" :title="$t('试驾车型')" :columns="modelList" :required="true" @change="changeVehicleCode"/> -->
      <van-form ref="carInfo" input-align="right" error-message-align="right">
        <van-field
          key="carInfo"
          :label="$t('试驾车型')"
          :placeholder="$t('请选择试驾车型')"
          :value="carInfo | formatCarInfo"
          right-icon="arrow"
          type="textarea"
          rows="1"
          autosize
          readonly
          required
          :rules="[{ required: true, trigger: 'onBlur' }]"
          @click="selectCarModel"
        />
      </van-form>
      <CellPicker v-model="formData.vinCode" :title="$t('试驾车')" :columns="testDriveList" valueKey="vinCode" labelKey="carNumber" :required="true" @change="changeCell"/>
      <CellPicker  v-model="formData.typeCode" :title="$t('试驾场景')" :required="true" :columns="getAppointmentType"/>
      <template v-if="formData.typeCode==='2002002'">
        <AddressSystem ref="addressSystem"
          :label="$t('试驾地点')"
          :form-data="addressObj"
          :keys="addressKeys"
          :placeholder="$t('请选择试驾地点')"
          :inputAlign="'right'"
          :isRequired="true"
          :rules="[{required:true}]"
          @change="onChangeAddress">
        </AddressSystem>
        <van-field v-model="formData.driveAddress" :label="$t('详细地址')" required maxlength="50" input-align="right"/>
      </template>
    </div>
    <calendarDateVue v-if="formData.vinCode"  class="box" :isRemove="false" 
      :vinCode="formData.vinCode" 
      :minDate="minDate" 
      :appointmentType="appointmentType" 
      :date="getTimeDate"
      :startEndHour="18"
      :timeInterval="30"
      :startRange="['09:00','23:30']"
      :endRange="['09:00','23:30']" 
      @change="changeDate"/>
    <div v-if="list.length" class="box">
      <van-cell :title="$t('已预约信息')"/>
      <van-cell v-for="e in list" :key="e.id">
        <template #title>
          <span v-if="e.appointmentType">{{ e.followUserName||e.customerName }}</span>
          <span v-else>{{ e.operateUserName }}</span>
        </template>
        <template #extra>
          <div v-if="e.appointmentType" class="cell-content">
            <span>{{ e.appointmentTime }} </span>
            <span>{{ e.typeName || '--' }} </span>
            <span>{{e.statusName || '--'}}</span>
            </div>
          <div v-else class="cell-content">
            <span>{{ e.processTime }} </span> 
            <span>{{ dictMap[e.processResult] }} </span>           
            <span>{{ e.typeName }}</span>
          </div>
        </template>
      </van-cell>
    </div>
    <div class="btns">
      <van-button round class="btn" @click="close">{{ $t('取消') }}</van-button>
      <van-button round class="btn" type="primary" @click="confirm">{{ data.reviewStatus==='1'? $t('提交审核'): $t('提交') }}</van-button>
    </div>
  </div>
</template>
<style lang="less" scoped>
.page{
  padding:62px 16px 100px;
  height: 100%;
  overflow-y: auto;
  // position: relative;
  box-sizing: border-box;
  .box{ 
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
  }
}
.nav{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 46px;
  border-bottom: #f5f5f5 solid 1px;
  color: #0D171A;
  background: #EED484;
  box-sizing: border-box;
  padding-left: 16px;
  z-index: 2;
}
.cell-content{
  color: #969799;
  flex: 2;
  display: flex;
  text-align: right;
  >span:first-child{
    width: 40% !important;
    min-width: 86px;
  }
  >span{
    width: 30%;
  }
}
.btns{
  margin-top:20px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom:0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px 0;
  z-index: 2;
  .btn{
    width: 168px;
  }
}

</style>
