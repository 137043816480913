<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import cellList from './cell'
import emptyLoading from '../empty-loading/empty-loading'
import { i18n } from '@/main'
export default {
  components: {
    formSelectWrap,
    cellList,
    emptyLoading
  },
  props: {
    showPicker: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '请选择'
    },
    columns: {
      type: Array,
      default: function() {
        return []
      }
    },
    latestChannels: {
      type: Array,
      default: function() {
        return []
      }
    },
    loading: {
      type: Boolean
    },
    empty: {
      type: Boolean
    },
    searchList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      i18n,
      activeList: [], // 已选择的数据列表
      active: '', // 当前高亮的已选择的数据
      activeLevel: 0, // 当前活跃数据的所处
      cellActive: {}, // 向cell传递点击的数据
      activeIndex: undefined,
      searchActive: undefined,
      search: ''
    }
  },
  computed: {
    list: function() {
      if (this.activeLevel === 0) {
        return this.columns
      } else {
        if (this.active.children) return this.active.children
        const parentLevel = this.activeLevel - 1
        return this.activeList[parentLevel].children
      }
    }
  },
  watch: {
    list(value) {
      
    },
    searchList: {
      handler(val) {
        if (val) {
          this.searchActive = undefined
        }
      },
      deep: true
    }
  },
  methods: {
    onChange(item) {
      this.active = item
    },
    open() {
      this.$refs.wrap.open()
    },
    // 操作区点击
    optClick(item, index) {
      const optLength = this.activeList.length
      if (index === optLength && index !== 0) return
      this.cellActive = {}
      this.active = this.activeList[index - 1]
      const num = optLength - index
      this.activeList.splice(-num, num)
      this.activeLevel = index
      console.log('optClick')
    },
    // 响应cell组件
    onCellChange(data) {
      this.activeIndex = undefined
      // 先请求数据再根据数据决定是否渲染
      this.$emit('cellChange', data)
      const { level, active } = data
      this.activeList[level] = active
      this.activeList = [...this.activeList]
      this.active = active

      // 不存在子项则为末端叶子节点
      if (!this.active.children) return
      this.activeLevel = level + 1
    },
    // 点击返回按钮
    onClickArrow() {
      if (this.activeList.length) {
        // 返回上一级
        const index = this.activeList.length -1
        this.optClick(this.activeList[index], index)
      } else {
        this.$emit('cancel')
      }
    },
    getTitle({ channelCode, channelOne, channelTwo, channelThree, channelFour, channelFive }) {
      const str = [channelOne, channelTwo, channelThree, channelFour, channelFive].filter((e)=>!!e).join('/')
      return channelCode ? `(${channelCode}) | ${str}` : str
    },
    hightText({ channelCodeOne='', channelCodeTwo='', channelCodeThree='', channelCodeFour='', channelCodeFive='', channelOne='', channelTwo='', channelThree='', channelFour='', channelFive='' }) {
      const str = [`(${channelCodeOne})${channelOne}`, `(${channelCodeTwo})${channelTwo}`, `(${channelCodeThree})${channelThree}`, `(${channelCodeFour})${channelFour}`, `(${channelCodeFive})${channelFive}`].filter((e)=> e !== '()').join(' / ')
      let result = str
      if (!this.search) return result
      const regex = new RegExp(this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')
      return result.replace(regex, (match) => `<span style="background-color: #eed48473">${match}</span>`)
    },
    onSelect(index) {
      if (index !== this.activeIndex) {
        this.activeIndex = index
        this.searchActive = undefined
      } else {
        this.activeIndex = undefined
      }
    },
    onSearchSelect(index) {
      if (index !== this.searchActive) {
        this.searchActive = index
        this.activeIndex = undefined
      } else {
        this.searchActive = undefined
      }
    },
    onConfirm() {
      if (this.activeIndex !== undefined) {
        const arr = []
        const item = this.latestChannels[this.activeIndex]
        const list = ['channelOne', 'channelTwo', 'channelThree', 'channelFour', 'channelFive']
        for (let index = 0; index < list.length; index++) {
          const channelText = list[index]
          const channelId = `${channelText}Id`
          if (item[channelId]) {
            arr.push({ id: item[channelId], text: item[channelText] })
          } else {
            // 如果任何一个channelId不存在，就跳出循环，因为后续的也不会存在了
            break
          }
        }
        this.$emit('confirm', arr)
      } if (this.searchActive !== undefined) {
        const arr = []
        const item = this.searchList[this.searchActive]['channelVo']
        const list = ['channelOne', 'channelTwo', 'channelThree', 'channelFour', 'channelFive']
        for (let index = 0; index < list.length; index++) {
          const channelText = list[index]
          const channelId = `${channelText}Id`
          if (item[channelId]) {
            arr.push({ id: item[channelId], text: item[channelText] })
          } else {
            break
          }
        }
        this.$emit('confirm', arr)
      } else {
        this.$emit('confirm', this.activeList)
      }

    },
    onSearch() {
      this.$emit('onSearch', this.search)
    },
  }
}
</script>
<template>
  <form-select-wrap ref="wrap" :title="title"
                    :multiple="true"
                    :showPicker="showPicker"
                    @cancel="onClickArrow"
                    @confirm="onConfirm">
    <template v-if="loading || empty" #content>
      <empty-loading :loading="loading" :empty="empty"/>
    </template>
    <template v-else #content>
      <div id="form-select-wrap-cascader">
        <div id="content-opt" class="root-container">
          <van-search
            v-model.trim="search"
            clearable
            class="searchInput"
            :formatter="(value)=> value.replace(/\s+/g, '')"
            format-trigger="onBlur"
            :placeholder="i18n.t('请输入渠道名称或完整渠道简码进行搜索')"
            @search="onSearch"
            @clear="onSearch">
          </van-search>
          <span v-for="(item,index) in activeList" :key='index' class="opt" :class="{ active: active===item }"
                @click="optClick(item,index)">
            {{ item.text || item.dictName }}
          </span>
        </div>
        <div class="bg-list"></div>
        <div v-if="!activeList.length && latestChannels.length">
          <van-cell :border="false" >
            <template #title>
              <span style="font-size: 14px; font-weight: bold;">{{ i18n.t('最近使用渠道') }}</span>
            </template>
          </van-cell>
          <van-cell v-for="(item, index) in latestChannels" :key="index" border :title="getTitle(item)" @click="onSelect(index)">
            <template #right-icon>
              <van-icon v-show="activeIndex === index" name="success" size="22" color="#B9921A"></van-icon>
            </template>
          </van-cell>
        </div>
        <van-cell :border="false" >
          <template #title>
            <span style="font-size: 14px; font-weight: bold;">{{ i18n.t('选择更多渠道') }}</span>
          </template>
        </van-cell>
        <div v-if="searchList.length > 0">
          <van-cell v-for="(item, index) in searchList" :key="index" border @click="onSearchSelect(index)">
            <template #title>
              <div v-html="hightText(item.channelVo || {})"></div>
            </template>
            <template #right-icon>
              <van-icon v-show="searchActive === index" name="success" size="22" color="#B9921A"></van-icon>
            </template>
          </van-cell>
        </div>
        <cell-list v-else :list="list" :level="activeLevel" :cellActive="cellActive" @change="onCellChange"></cell-list>
      </div>
    </template>
  </form-select-wrap>
</template>
<style lang="less">
#form-select-wrap-cascader {
  .searchInput{
    padding: 0;
    border-radius: 20px;
    margin-bottom: 10px;
    .van-search__content {
      background-color: #f5f5f5;
      border-radius: 24px;
    }
    // placeholder字体颜色
    /deep/ input::placeholder {
      color: rgba(13,23,26,0.45);
    }
    // 前缀图标颜色
    /deep/.van-icon-search{
      color: #666;
      font-size: 15px;
    }
    /deep/.van-field__left-icon{
      margin-right: 6px;
    }
  }
  #content-opt {
    padding: 10px 10px 0;
    .disabled{
      color: gray;
      &.active {
        background-color: @yellow;
        color: white;
      }
    }
    > .opt {
      display: inline-block;
      background-color: #F5F5F5;
      padding: 3px 10px 4px;
      margin-right: 10px;
      margin-bottom: 10px;

      &.active {
        background-color: @yellow;
        color: @black;
      }
    }
  }

  .bg-list {
    background: #F5F5F5;
    height: 1.5rem;
  }
}
</style>
