/*
 * @Author: 张超越 
 * @Date: 2024-03-16 13:29:11 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-05-06 10:37:44
 */

<template>
  <div class="ClueTags">
    <div v-for="(item, index) of getInfosWithoutOnlyEditItem" :key="index" class="item-box">
      <div class="title-box">
        <div class="title">{{ item.name }}</div>
        <img v-if="canTagEdit" src="@/images/edit_yyellow.svg" alt="" @click="handleShowEdit(item)">
      </div>
      
      <van-row class="blocks">
        <van-col v-for="(block, BIndex) of item.items" :key="BIndex" :span="block.type === 'hr' || block.keyCode === 'hobby' ? 24 : (block[getLangIsZH ? 'span' : 'spanEn'] || 12)" class="block-box">
          <!-- 需求变动，二级分组名 -->
          <div v-if="block.type === 'hr'" class="hr">{{ block.keyName }}</div>
          
          <!-- tags -->
          <div v-else-if="block.type === 'list'" class="tags">
            <template v-if="!block.value || !block.value.length">-</template>
            <template v-else>
              <div v-for="(tag, TIndex) of block.value" :key="TIndex" class="tag-item">
                <div class="tag">
                  {{ getHobbyDName(tag) }}
                </div>
                <!-- <div v-if="getHobbyDDescription(tag)" class="description">{{ getHobbyDDescription(tag) }}</div> -->
              </div>
            </template>
          </div>
            
          <template v-else>
            <div class="name">{{ block.name }}</div>
            <div class="value">
              <!-- 常规 -->
              <template v-if="block.type === 'area'">{{ getAddressValue(block.value) || '-' }}</template>
              <template v-else-if="block.type === 'city'">{{ getAddressValue(block.value) || '-' }}</template>
              <template v-else-if="block.type === 'model'">{{ getCarValue(block.value) || '-' }}</template>
              <template v-else-if="block.type === 'multi_select'">{{ getMDValue(block.value, block.keyCode) || '-' }}</template>
              <template v-else-if="block.type === 'multi_cascader'">{{ getMCValue(block.value, block.keyCode) || '-' }}</template>
              <template v-else-if="block.type === 'competitor_model'">{{ getCMValue(block.value, block.keyCode) || '-' }}</template>
              <template v-else>
                <div v-if="block.keyCode === 'carOrderMaterial'" style="text-align: right;">
                  <div v-if="block.value">
                    <div>车系车型：{{ block.value?.seriesName }} {{ block.value?.modelName }}</div>
                    <div>外饰颜色：{{ block.value?.colourName }}</div>
                    <div>内饰颜色：{{ block.value?.interiorName }}</div> 
                    <!-- <div v-if="clueDetail.orders?.[0].carOrderMaterial?.carOptionsList">选装：
                      <div v-for="(item, index) in clueDetail.orders?.[0].carOrderMaterial?.carOptionsList" :key="index">{{index + 1}}.{{ item.optionsName }}</div>
                    </div> -->
                  </div>
                </div>
                <div v-else-if="block.keyCode === 'concernRemark'">
                  <!-- https://github.com/wintc23/vue-overflow-ellipsis -->
                  <ellipsis class="concern-remark" :content="getDName(block.value, block.keyCode)" :rows="2" btnText="全部" @click-btn="showAllConcernRemark(block.value, block.keyCode)"></ellipsis>
                </div>
                <div v-else> {{ getDName(block.value, block.keyCode) || '-' }}</div>
              </template>
            </div>
          </template>
        </van-col>
      </van-row>
    </div>

    <!-- 弹窗 -->
    <!-- 统一编辑 -->
    <van-popup v-model="status.edit" style="width:100%; height:100%">
      <EditInformation v-if="status.edit" :title="getEditName" :clueInfo="clueInfo" :dictionaries="dictionaries" :details="getEditDetailsItems" :leadId="id" :keyCode="'base'" :isHatch="isHatch" @close="status.edit = false" @ok="handleEditOK"></EditInformation>
    </van-popup>
    <!-- 兴趣爱好 -->
    <van-popup v-model="status.hobby" style="width:100%; height:100%">
      <SelectTags v-if="status.hobby" :title="getHobbyDetails.name" :details="getHobbyDetailsItems" :leadId="id" :keyCode="'hobby'" @close="status.hobby = false" @ok="handleHobbiesOK"></SelectTags>
    </van-popup>
    <van-dialog v-model="status.concernRemark" title="客户顾虑" confirm-button-text="关闭">
      <div style="padding: 10px;word-break: break-all;">{{ concernRemarkStr }}</div>
    </van-dialog>
  </div>
</template>

<script>
// Tools
import { groupBy, isArray, cloneDeep, isString } from 'lodash'
import { i18n } from '@/main'

// APIs
import clueServices from '@/services/clueServices'
import baseDataServices from '@/services/baseDataServices'

// Components
import EditInformation from './EditInformation.vue'
import SelectTags from './SelectTags.vue'
import loading from '@/utils/loading'

import ellipsis from 'vue-overflow-ellipsis'
import 'vue-overflow-ellipsis/dist/index.css'

export default {
  name: 'ClueTags',
  components: {
    EditInformation,
    SelectTags,
    ellipsis,
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    clueInfo: {
      type: Object,
      default: () => {}
    },
    canTagEdit: {
      type: Boolean,
      default: true
    },
    // // 是否为培育线索
    isHatch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editName: '编辑',
      status: {
        hobby: false,
        society: false,
        base: false,
        buy: false,
        edit: false,
        gName: '',
        concernRemark: false
      },
      concernRemarkStr: '',
      dictionaries: [],
      infos: [
        {
          name: '基础信息',
          items: [
            { name: '性别', keyName: '', value: '' },
            { name: '年龄', keyName: '', value: '' },
            { name: '驾龄', keyName: '', value: '' },
            { name: '家庭人口', keyName: '', value: '' },
            { name: '婚姻状况', keyName: '', span: 24, value: '' },
            { name: '居住地址', keyName: '', span: 24, value: '' },
            { name: '居住小区', keyName: '', span: 24, value: '' },
            { name: '其他联系方式1', keyName: '', span: 24, value: '' },
            { name: '其他联系方式2', keyName: '', span: 24, value: '' },
            { name: '其他联系方式3', keyName: '', span: 24, value: '' },
          ]
        },
        {
          name: '购买信息',
          items: [
            { name: '意向购车信息', keyName: '', value: '' },
            { name: '是否了解电车', keyName: '', value: '' },
            { name: '车辆指标', keyName: '', value: '' },
            { name: '用车指标', keyName: '', value: '' },
            { name: '本品了解程度', keyName: '', value: '', span: 24 },
            { name: '动态驾驶关注点', keyName: '', value: '', span: 24 },
            { name: '', keyName: '', type: 'hr', value: '' },
            { name: '预计购车时间', keyName: '', value: '' },
            { name: '付款方式', keyName: '', value: '' },
            { name: '共同决策人', keyName: '', value: '' },
            { name: '共同决策人是否试驾', keyName: '', value: '' },
            { name: '竞品品牌车型', keyName: '', value: '', span: 24 },
            { name: '竞品关注点', keyName: '', value: '', span: 24 },
            { name: '静态讲解关注点', keyName: '', value: '', span: 24 },
            { name: '', keyName: '', type: 'hr', value: '' },
            { name: '购车用途', keyName: '', value: '' },
            { name: '目前在用车辆', keyName: '', value: '' },
            { name: '用车人', keyName: '', value: '' },
            { name: '用车城市', keyName: '', value: '' },
            { name: '购车类型', keyName: '', value: '' },
            { name: '置换需求', keyName: '', value: '' },
            { name: '购车预算', keyName: '', value: '' },
            { name: '是否大客户', keyName: '', value: '' },
            { name: '是否有固定车位', keyName: '', value: '', span: 24 },
          ]
        },
        {
          keyName: 'hobbies',
          name: '兴趣爱好',
          type: 'list',
          items: [
            // { name: '未知', keyName: '', value: '' },
            // { name: '游戏', keyName: '', value: '' },
            // { name: '舞蹈', keyName: '', value: '' },
            // { name: '运动', keyName: '', value: '' },
            // { name: '相声', keyName: '', value: '' },
          ]
        },
        {
          name: '社会信息',
          items: [
            { name: '学历', keyName: '', value: '' },
            { name: '行业', keyName: '', value: '' },
            { name: '职业', keyName: '', value: '' },
            { name: '单位性质', keyName: '', value: '' },
            { name: '职务', keyName: '', value: '' },
            { name: '收入', keyName: '', value: '' },
          ]
        }
      ]
    }
  },
  computed: {
    getEditName() {
      return this.getInfos.find(i => i.gName === this.status.gName)?.name || '编辑'
    },
    getInfos() {
      return this.infos
    },
    getInfosWithoutOnlyEditItem() {
      return this.getInfos.map(i => {
        let items = i.items

        if (isArray(i.items) && i.type !== 'list') {
          items = cloneDeep(i.items).filter(j => {
            // 额外处理公司名称
            if (j.keyCode === 'userType' && j.value == 2) {
              const companyNameItem = i.items.find(k => k.keyCode === 'companyName')

              j.value = [this.getDName(j.value, j.keyCode), companyNameItem?.value || ''].join(' / ')
              j.span = 24
              j.spanEn = 24
              return true
            }

            return [1, 2].includes(j.dataType)
            // 数据类型 1 读写 2读 3 仅写
          }) 
        }
        return {
          ...i,
          items
        }
      })
    },
    getEditDetailsItems() {
      const item = this.getInfos.find(i => i.gName === this.status.gName)
      return item?.items || []
    },
    getHobbyDetails() {
      const item = this.infos.find(i => i.gName === 'hobby')
      return item
    },
    getHobbyDetailsItems() {
      return this.getHobbyDetails.items || []
    },
    getSocietyDetails() {
      const item = this.getInfos.find(i => i.gName === 'society')
      return item
    },
    getSocietyDetailsItems() {
      return this.getSocietyDetails.items || []
    },
    getBaseDetails() {
      const item = this.getInfos.find(i => i.gName === 'base')
      return item
    },
    getBaseDetailsItems() {
      return this.getBaseDetails.items || []
    },
    getBuyDetails() {
      const item = this.getInfos.find(i => i.gName === 'buy')
      return item
    },
    getBuyDetailsItems() {
      return this.getBuyDetails.items || []
    },
    getLangIsZH() {
      const lang = i18n.locale
      return lang.includes('zh')
    }
  },
  created() {
    console.info('clueInfo', this.clueInfo)
    this.init()
  },
  mounted() {
  },
  methods: {
    async init() {
      // 先查询所有标签的字典
      this.getAndSetTD()

      const res = await clueServices.getCluesTags({ id: this.id })
      console.info('tags res', res)
      const infosGN = groupBy(res, 'groupCode')
      console.info('infosGN', infosGN)

      const infos = Object.entries(infosGN)
      console.info('infos', infos)

      this.infos = infos.map(arr => {
        let [gName = '', items = []] = arr
        const name = items[0].groupName

        items = items
          .map(i => {
            const { keyName: name, keyCode } = i
            // 如果是兴趣爱好，额外处理
            if (keyCode === 'hobby') {

              // 如果是字符串，则用英文逗号分隔
              if (isString(i.value)) {
                i.value = i.value.split(',')
              }
            }
            return {
              ...i,
              name
            }
          })
        if (this.isHatch) {
          items = items.filter(item => item.keyCode !== 'leadUserLevel')
        }
        return {
          gName,
          name,
          items
        }
      })

      console.info('this.infos', this.infos)

      this.onBackEvent()
    },
    async getAndSetTD() {
      loading.showLoading()
      const res = await baseDataServices.getTagsDicsByKey().finally(() => loading.hideLoading())

      this.dictionaries = res
    },
    handleShowEdit(item) {
      console.info('handleShowEdit', item)
      const { gName } = item
      if (!gName) return false

      this.status.gName = gName
      this.status.edit = true
    },
    handleEditOK(values) {
      console.info('handleEditOK', values)
      this.status.edit = false
      this.init()
      this.$emit('updateClueInfo')
    },
    handleSocietyOK(values) {
      console.info('handleSocietyOK', values)
      this.status.society = false
      this.init()
    },
    handleHobbiesOK(ids) {
      this.status.hobby = false
      this.init()
    },
    handleBaseOK() {
      this.status.base = false
      this.init()
      this.$emit('updateClueInfo')
    },
    handleBuyOK() {
      this.status.buy = false
      this.init()
      this.$emit('updateClueInfo')
    },
    getCarValue(obj = {}) {
      console.info('getCarValue', obj)
      if (typeof(obj) !== 'object') return obj
      const { seriesName = '', modelName = '' } = obj
      return `${seriesName} ${modelName}`
    },
    getDItems(key) {
      return this.dictionaries
        .filter(i => i.keyCode == key)
        .map(i => {
          const { valueName: name } = i
          return {
            ...i,
            name
          }
        })
    },
    getCMValue(valueCode, keyCode) {
      console.info('getCMValue', valueCode, keyCode)
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, code, models } = item
        let str = this.getLangIsZH ? name : nameEn

        let modelsStr = []
        if (models && isArray(models) && models.length) {
          models.forEach(j => {
            const { name, nameEn, code } = j
            modelsStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (modelsStr && modelsStr.length) {
            str += this.$t('：')
            str += ' ' + modelsStr.join(this.$t('，'))
          }
        }

        return str
      })

      return strs.join(this.$t('；'))
    },
    getMCValue(valueCode, keyCode) {
      console.info('getMCValue', valueCode, keyCode)
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, value, children } = item
        let str = this.getLangIsZH ? name : nameEn

        let cStr = []
        if (children && isArray(children) && children.length && children.length > 0) {
          str += this.$t('：')
          children.forEach(j => {
            const { name, nameEn, value } = j
            cStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (cStr && cStr.length) {
            str += ' ' + cStr.join(this.$t('，'))
          }
        }

        return str
      })

      return strs.join(this.$t('；'))
    },
    getMDValue(valueCode, keyCode) {
      console.info('getMDValue', valueCode, keyCode)
      if (!valueCode || !valueCode.length) return ''

      const items = this.getDItems(keyCode)
      console.info('items', items)
      valueCode = isArray(valueCode) ? valueCode : valueCode.split(';')

      const item = valueCode
        .map(i => items.find(j => j.valueCode == i) || { name: i })
        .map(i => i?.name)

      console.info('getMDValue item after', keyCode, item)

      return item.join(this.$t('、'))
    },
    getAddressValue(arr) {
      console.info('getAddressValue', arr)

      if (!isArray(arr)) {
        return arr
      }

      if (!arr || !arr.length) return ''
      let arr2 = (arr || []).map(i => i.name)

      // 如果是英文，则翻转
      if (!this.getLangIsZH) {
        arr2 = arr2.reverse()
      }

      const str = arr2.join(' ')
      return str
    },
    getDName(valueCode, keyCode) {
      // console.info('getDName', valueCode, keyCode)
      if (!String(valueCode)) return ''

      const items = this.dictionaries.filter(i => i.keyCode === keyCode)
      const item = items.find(i => i.valueCode == valueCode)

      // return item
      return item?.valueName || valueCode
    },
    getHobbyDName(tagCode) {
      const tags = this.dictionaries.filter(i => i.keyCode === 'hobby') || []
      const item = tags.find(i => i.valueCode == tagCode)

      return item?.valueName || tagCode
    },
    getHobbyDDescription(tagCode) {
      const map = {
        101140: this.getLangIsZH ? 'Jazz music festival' : 'Jazz music festival', // 音乐
        101225: this.getLangIsZH ? 'Meta LBS community interaction' : 'Meta LBS community interaction', // 美食
        101215: this.getLangIsZH ? 'Animation festival events' : 'Animation festival events', // 二次元
        101170: this.getLangIsZH ? 'Meta LBS community interaction' : 'Meta LBS community interaction', // 旅游
      }

      return map[tagCode] || ''
    },
    onBackEvent() {
      setTimeout(() => {
        try {
          wx.onHistoryBack(() => {
            const bools = [this.status.edit, this.status.hobby, this.status.society, this.status.base, this.status.buy]

            if (bools.some(Boolean)) {
              for (let key in this.status) {
                this.status[key] = false
              }
              return false
            }
            return true
          })
        } catch (error) {
          console.error(error)
        }
      }, 1500)
    },
    showAllConcernRemark(value, keyCode) {
      this.status.concernRemark = !this.status.concernRemark
      this.concernRemarkStr = this.getDName(value, keyCode)
    },
  }
}
</script>

<style lang="less" scoped>
.ClueTags {
  padding-left: 5px;
  padding-right: 5px;
  .item-box {
    border-top: 6px solid #f3f3f3;
    margin-top: 6px;

    &:first-child {
      border-top-width: 1px;
      margin-top: 10px;
    }
    .title-box {
      display: flex;
      align-items: center;
      .title {
        flex: 1;
        width: 64px;
        height: 24px;
        font-weight: normal;
        font-size: 16px;
        color: #0D171A;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .blocks {
      .block-box {
        display: flex;
        // align-items: center;
        margin-bottom: 6px;

        .name {
          min-height: 16px;
          font-weight: normal;
          font-size: 13px;
          color: rgba(13,23,26,0.45);
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          word-break: keep-all;
          &::after{
            content: '：';
          }
        }

        .value {
          min-height: 16px;
          font-weight: normal;
          font-size: 13px;
          color: #0D171A;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          .text-ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5em;
            max-height: 3em; 
            word-break: break-all;
            position: relative;
            .expand-btn {
              position: absolute;
              top: 20px;
              right: 5px;
              padding-left: 2px;
              background-color: #fff;
            }
          }
          .concern-remark{
            /deep/.ellipsis-btn {
              color: #eed484 !important;
            }           
          }
        }
      }

      .hr {
        margin: 4px 0 2px 0;
        display: flex;
        align-items: center;
        &::before {
          content: ' ';
          background-color: #B9921A;
          width: 3px;
          height: 12px;
          display: inline-block;
          margin-right: 10px;
        }
      }
      
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      .tag-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        overflow: hidden;
        .tag{
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .description {
          font-size: 13px;
          color: #0D171A;
        }
      }
      .tag {
        height: 20px;
        line-height: 20px;
        background-color: #f8f4e8;
        color: #b9921a;
        font-size: 13px;
        padding: 0 8px;
        margin-right: 8px;
        border-radius: 2px;
        word-break: keep-all;
      }
    }
  }
}
</style>