<template>
	<ul class="card-item-options">
		<li class="item">{{ data | modelNameFilter}}</li>
		<!--  未试驾显示“试驾意向等级” -->
		<li v-if="!data.drivingTime && getLevel" class="item d-level">{{ getLevel }}</li>
		<!-- 试驾后不显示“试驾意向等级”，要显示“购车意向等级”未试驾的线索，若购车评级为H 两个等级同时显示 -->
		<li v-if="(data.leadLevel == 'H' || data.leadUserLevel == 'H') || (data.drivingTime && (data.leadLevel || data.leadUserLevel))" class="item leadLevel" >{{ getLeadLevel }}</li>
		<!--未试驾显示排程情况 -->
		<li v-if="!data.drivingTime" class="item green" > {{ data.appointmentTime ?  $t('Scheduled') : $t('Unscheduled') }}</li>
		<!--已试驾显示试驾次数 -->
		<li v-if="data.driveTimes" class="item orange">
			<span v-if="i18n.locale.includes('zh')">
				已试驾{{ data.driveTimes }}次
			</span>
			<span v-else>
				{{ `Test drive ${data.driveTimes} times` }}
			</span>
		</li>
		<!-- 选配情况 -->
		<li class="item grey" >{{[1006001, 1006003].includes(+data.isDriveOption) ? $t('Optional') : $t('Not_optional')}}</li>
    <!-- 媒体线索类型 0 普通线索，1 小订线索，2 试驾线索，3活动线索 4询价线索 5置换线索 6转介绍线索-->
    <li v-if="[5, 6].includes(data.mediaLeadType)" class="item d-level">{{data.mediaLeadType === 5 ? $t('Replacement') : $t('Referrals')  }}</li>
		<!-- 长期战败 -->
    <li v-if="data.defeatTimes >= 2" class="item red">{{$t('长期战败')}}</li>
		<!-- 长期无效 -->
    <li v-if="data.invalidTimes >= 2" class="item red">{{$t('长期无效')}}</li>
		<!-- 客户不愿被联系 -->
    <li v-if="data.customerNoChat === 1" class="item red">{{$t('客户不愿被联系')}}</li>
	</ul>
</template>
<script>
import { i18n } from '@/main'
export default {
  filters: {
    modelNameFilter(data) {
      const { seriesName, modelName } = data
      if (!seriesName && !modelName) return this.$t('No_intentional_models')
      return [seriesName, modelName].join(' ')
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      i18n
    }
  },
  computed: {
    getLevel() {
      const code = this.data.tryDriveLevel
      const obj = this.$store.getters.getDictHash(1012).find((item) => {
        return item.code === code
      })
      return obj && obj.name
    },
    // 显示购车意向等级标签的规则调整为 ： 显示购车意向等级 和 顾问评定等级 中级别更高的结果 。  若两者等级一致，则显示系统计算的购车意向等级
    getLeadLevel() {
      const list = ['H', 'A', 'B', 'C', 'U']
      const leadLevelIndex = list.findIndex(item => item === this.data.leadLevel)
      const leadUserLevelIndex = this.data.leadUserLevel ? list.findIndex(item => item === this.data.leadUserLevel) : leadLevelIndex
      return leadLevelIndex !== -1 && leadLevelIndex <= leadUserLevelIndex ? this.data.leadLevel : this.data.leadUserLevel
    }
  },
}
</script>
<i18n>
{
	"en": {
		"No_intentional_models": "No intentional models",
		"Unscheduled": "Unscheduled",
		"Scheduled": "Scheduled",
		"Optional": "Option",
		"Not_optional": "No option",
		"Referrals": "Referrals",
		"Replacement": "Replacement"
	},
	"zh": {
		"No_intentional_models": "无意向车型",
		"Unscheduled": "未排程",
		"Scheduled": "已排程",
		"Optional": "已选配",
		"Not_optional": "未选配",
		"Referrals": "转介绍",
		"Replacement": "置换"
	}
}
</i18n>
<style lang="less" scoped>
.card-item-options {
	display: flex;
	flex-wrap: wrap;
	margin-left: -4px;
	margin-right: -4px;
	margin-bottom: 8px;
	.item {
		padding: 2px 8px;
		border-radius: 4px;
		border-width: 1px;
		border-style: solid;
		margin-top: 8px;
		margin-left: 4px;
		line-height: 16px;
		font-size: 12px;

		&:first-child {
			border-color: rgba(0, 64, 197, 0.1);
			color: #0040c5;
			background: rgba(0, 64, 197, 0.1);
		}
		&.d-level {
			color: #b9921a;
			background: rgba(185, 146, 26, 0.1);
			border-color: rgba(185, 146, 26, 0.1);
		}
		&.green {
			color: #029640;
			background: rgba(2, 150, 64, 0.1);
			border-color: rgba(2, 150, 64, 0.1);
		}
		&.red {
			color: #e4002c;
			background: rgba(228, 0, 44, 0.1);
			border-color: rgba(228, 0, 44, 0.1);
		}
		&:last-child {
			margin-right: 4px;
		}
	}
  .leadLevel{
    color: #e4002c;
    background: rgba(228,0,44,0.1);
		border-color: rgba(228, 0, 44, 0.1);
  }
  .orange{
    color: #FF7D00;
    background: rgba(255,125,0,0.1);
		border-color: rgba(255,125,0,0.1);
  }
  .grey{
    color: #666;
    background: rgba(102,102,102,0.1);
		border-color: rgba(102,102,102,0.1);
  }
	// .H {
	// 	color: #e4002c;
	// 	background: rgba(228, 0, 44, 0.1);
	// 	border-color: rgba(228, 0, 44, 0.1);
	// }
	// .A {
	// 	color: #fa5410;
	// 	background: rgba(250, 84, 16, 0.1);
	// 	border-color: rgba(250, 84, 16, 0.1);
	// }
	// .B {
	// 	color: #faad14;
	// 	background: rgba(250, 173, 20, 0.1);
	// 	border-color: rgba(250, 173, 20, 0.1);
	// }
	// .C {
	// 	color: #2f54ed;
	// 	background: rgba(0, 64, 197, 0.1);
	// 	border-color: rgba(0, 64, 197, 0.1);
	// }
	// .U {
	// 	color: #b0b3b4;
	// 	background: rgba(176, 179, 180, 0.1);
	// 	border-color: rgba(176, 179, 180, 0.1);
	// }
}
</style>
